import React from "react";
import Img from "gatsby-image";

import Layout from "../components/layout";

import "../components/style.scss";

import savills from "../images/work/Savills_Summer_Cocktail.jpg"
import laCamara from "../images/work/la_camara.jpg"
import digicit from "../images/work/digicit.jpg"

const quotes = [{
  name: "Marie Luketic",
  title: "National Manager - Corporate Strategy & Development at Savills Australia & New Zealand",
  quote: "I can highly recommend Mar Barahona as a corporate photographer. Her level and quality of service at our recent cocktail event for 600 guests was impeccable. The quality of photos taken on the evening were amazing and the turnout exceptionally fast. I have no hesitation in recommending her to any firm.",
  image: savills
},{
  name: "Marion Bingham",
  title: "Marketing Manager at Spanish-Australian Chamber of Commerce", quote: "Mar captured the atmosphere of the evening brilliantly! The images are cozy and intimate, but also show the fun and exciting bits of the evening.",
  image: laCamara
},{
  name: "Ariane Fricke",
  title: "Executive Manager Strategy at NBN. Head Organiser at Digicit",
  quote: "I was very impressed with the images. Mar captured the atmosphere of the evening brilliantly! The images are cozy and intimate, but also show the fun and exciting bits of the evening.",
  image: digicit
}]

export default () => (
  <Layout>
    <section className="section">
      <div className="container">
        {quotes.map(quote => (
          <div className="columns">
            <div className="column is-7 is-offset-1">
              <figure className="image">
                <img src={quote.image} className="" />
              </figure>
            </div>
            <div className="column is-3">
              <div className="box">
                <article className="media">
                  <div className="media-content">
                    <div className="content">
                      <p>
                        <strong>{quote.name}</strong>
                        <br />
                        <small>{quote.title}</small>
                        <br />
                        <br />
                        {quote.quote}
                      </p>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  </Layout>
);
